import _axios from "axios";
import checkoutApiUrl from "../utils/checkoutApiUrl";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const submitOrders = async (
  token,
  orders,
  orderData = {},
  apiVersion2 = false
) => {
  if (!token) {
    console.log("Submit Order Error: Token not present!");
    return;
  }

  const receiptPageUrl = apiVersion2
    ? `${process.env.GATSBY_CHECKOUT_SITE_URL}/${token}/receipt`
    : `${checkoutApiUrl()}/checkout/${token}/receipt`;
  if (orders.length === 0) {
    window.location.href = receiptPageUrl;
    return;
  }

  try {
    const checkoutData = orders.map(({ checkoutData, productId, ...rest }) => {
      if (apiVersion2) {
        return {
          product_id: Number(productId),
          quantity: Number(checkoutData.quantity),
          image_url: checkoutData.image,
          schedule: "",
          title: checkoutData.title,
          pet_size: "small",
          is_upsell: true
        };
      }
      return {
        ...checkoutData,
        tag: rest.tag,
        tax: rest.tax,
        taxLabel: rest.taxLabel,
        taxWithDiscount:
          parseFloat(checkoutData.discounted_price) + parseFloat(rest.tax)
      };
    });

    const discountCodes = orders.map(order => {
      return order.discountCode;
    });

    const variantIds =
      orders.map(order => {
        return order.variantId;
      }) || [];

    return await _axios
      .post(`${checkoutApiUrl(apiVersion2)}/checkout/${token}/upsell`, {
        discount_codes: [...discountCodes],
        ...(apiVersion2
          ? { line_items: checkoutData }
          : { items: checkoutData })
      })
      .then(response => {
        const email = apiVersion2 ? orderData.email : orderData.customer.email;
        // drop cookie
        const userCookies = cookies.get(email) || [];
        cookies.set(email, [...new Set([...userCookies, ...variantIds])], {
          path: "/"
        });

        // redirect
        window.location.href = apiVersion2
          ? receiptPageUrl
          : response?.data?.redirect;
      });
  } catch (e) {
    console.log("error submitting orders");
    console.log(e);
    window.location.href = receiptPageUrl;
  }
};

export default submitOrders;
