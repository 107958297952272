import { useContext } from "react";
import Context from "../context/Context";

export const useCurrentOffer = (
  initialIndex = 0,
  forceDefaultIndex = false
) => {
  const {
    selectorData: { offers },
    currentIndex,
    salesTaxes,
    isCheckoutApiVersion2
  } = useContext(Context);

  const currentOffer = forceDefaultIndex
    ? offers.at(initialIndex)
    : offers.at(currentIndex);

  const {
    nextIndexYes,
    checkoutData: { discounted_price }
  } = currentOffer;

  const { tax: taxes, label: taxLabel, tax_rate = 0 } = salesTaxes || {};
  const taxV1 = taxes ? taxes[`offer${currentIndex}`] : 0;
  const taxV2 = discounted_price * tax_rate;
  const currentTax = isCheckoutApiVersion2 ? taxV2 : taxV1;

  return {
    ...currentOffer,
    offerIndex: forceDefaultIndex ? initialIndex : currentIndex,
    tax: currentTax,
    taxLabel: taxLabel || "Sales tax",
    hasNextOffer: typeof offers.at(nextIndexYes) !== "undefined"
  };
};
