import { useContext } from "react";
import Context from "../context/Context";
import { useCurrentOffer } from "./useCurrentOffer";
import { combineCartItems } from "../utils/upsellCart";
import submitOrders from "../functions/submitOrders";

export const useSubmitOrders = () => {
  const {
    setCurrentIndex,
    setButtonClicked,
    selectorData,
    upsellCart,
    setUpsellCart,
    userToken,
    orderData,
    isCheckoutApiVersion2
  } = useContext(Context);

  const currentOffer = useCurrentOffer();

  const confirmOrder = () => {
    const { nextIndexYes, hasNextOffer } = currentOffer;

    // add to order
    const cartItems = combineCartItems(upsellCart, currentOffer);
    setUpsellCart(cartItems);

    const moveNext = () => {
      // disable loading
      setButtonClicked(false);

      // scroll to top
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };

    if (!hasNextOffer) {
      // submit order
      return submitOrders(
        userToken,
        cartItems,
        orderData,
        isCheckoutApiVersion2
      ).then(results => {
        setButtonClicked(false);
        return results;
      });
    } else {
      setCurrentIndex(nextIndexYes);
      moveNext();
    }
  };

  const declineOrder = () =>
    submitOrders(userToken, [], {}, isCheckoutApiVersion2);

  return {
    declineOrder,
    confirmOrder
  };
};
