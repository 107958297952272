import styled from "styled-components";

export const Button = styled.button`
  &,
  &:focus {
    padding: 10px;
    cursor: pointer;
    display: block;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    background: #f4d078;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f7dfa5),
      to(#f0c14b)
    );
    background: linear-gradient(180deg, #f7dfa5, #f0c14b);
    border-radius: 6px;
    border-color: #707070;
    border-style: solid;
    border-width: 1px;
    min-height: 60px;
    width: 100%;
  }

  &:hover {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f7e6bc),
      to(#f0c14b)
    );
    background: linear-gradient(180deg, #f7e6bc, #f0c14b);
  }

  & .button-loading {
    display: block;
    margin: 5px auto;
  }

  &.clicked {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.design2 {
    background: none;
    background-color: #e26447;
    border-radius: 5px;
    border-color: #707070;
    border-style: solid;
    border-width: 0px;
    border-bottom: 5px solid #a54e32;

    &:hover {
      border-bottom: 5px solid #e26447;
      & > div {
        margin-top: 5px;
      }
    }
  }
`;
