import React from "react";
import AcceptButtonV0 from "./AcceptButtonV0";
import AcceptButtonV1 from "./AcceptButtonV1";
import AcceptButtonAddSub from "./AcceptButtonAddSub";

export default function AcceptButton(props) {
  const { version } = props;
  switch (version) {
    case "add-sub":
      return <AcceptButtonAddSub {...props} />;
    case "no-design":
      return <AcceptButtonV0 {...props} />;
    case "version1":
    default:
      return <AcceptButtonV1 {...props} />;
  }
}
