import React, { useContext, useState } from "react";
import Context from "../../context/Context";
import { Builder } from "@builder.io/react";
import * as S from "./AcceptButtonV0.styles";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup";
import { useCurrentOffer } from "../../hooks/useCurrentOffer";
import { useSubmitOrders } from "../../hooks/useSubmitOrders";

const AcceptButtonComponent = ({
  children,
  confirmationVersion,
  loadingBgColor,
  showConfirmation,
  noTaxComputation = false,
  borderRadius,
  minHeight,
  isPreviewLoading = false
}) => {
  const { buttonClicked, setButtonClicked } = useContext(Context);

  const [showConfirmationPopup, setConfirmationShowPopup] = useState(false);

  const currentOffer = useCurrentOffer();
  const { confirmOrder, declineOrder } = useSubmitOrders();

  const triggerButton = () => {
    if (buttonClicked || Builder.isEditing) return;

    if (showConfirmation) {
      return setConfirmationShowPopup(true);
    }

    setButtonClicked(true);
    confirmOrder();
  };

  const ButtonContent = ({ children }) => {
    const isPreview = isPreviewLoading && Builder.isPreviewing;
    if (buttonClicked || isPreview) {
      return (
        <S.LoadingButton
          loadingBgColor={loadingBgColor}
          borderRadius={borderRadius}
          minHeight={minHeight}
        >
          <img
            src="/images/three-dots.svg"
            width={120}
            height={30}
            alt="Loading..."
            className="button-loading"
          />
        </S.LoadingButton>
      );
    } else {
      return children;
    }
  };

  return (
    <>
      <S.Button
        className={`${buttonClicked ? "clicked" : ""} accept-button no-design`}
        onClick={triggerButton}
      >
        <ButtonContent>
          <div>{children || `{add text component}`}</div>
        </ButtonContent>
      </S.Button>
      {showConfirmationPopup && (
        <ConfirmationPopup
          version={confirmationVersion}
          currentOrders={[currentOffer]}
          showPopup={showConfirmationPopup}
          setShowPopup={setConfirmationShowPopup}
          confirmOrder={confirmOrder}
          declineOrder={declineOrder}
          noTaxComputation={noTaxComputation}
        />
      )}
    </>
  );
};

export default AcceptButtonComponent;
