import React, { useContext, useState } from "react";
import Context from "../../context/Context";
import submitOrders from "../../functions/submitOrders";
import * as S from "./AcceptButtonAddSub.styles";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup";
import { combineCartItems } from "../../utils/upsellCart";
import submitUpgradeToSub from "../../functions/submitUpgradeToSub";
import { useCurrentOffer } from "../../hooks/useCurrentOffer";
import { Builder } from "@builder.io/react";

const AcceptButtonComponent = ({
  children,
  loadingBgColor,
  showConfirmation,
  subProduct,
  popupTopImage
}) => {
  const {
    setCurrentIndex,
    buttonClicked,
    setButtonClicked,
    upsellCart,
    setUpsellCart,
    userToken,
    orderData,
    isCheckoutApiVersion2
  } = useContext(Context);

  const [showConfirmationPopup, setConfirmationShowPopup] = useState(false);

  const currentOffer = useCurrentOffer();
  const { nextIndexYes, hasNextOffer } = currentOffer;

  const confirmSub = () => {
    // add to order
    const cartItems = combineCartItems(upsellCart, currentOffer);
    setUpsellCart(cartItems);

    const moveNext = () => {
      // disable loading
      setButtonClicked(false);

      // scroll to top
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };

    if (!hasNextOffer) {
      // submit order
      return submitUpgradeToSub(
        userToken,
        cartItems,
        orderData,
        subProduct
      ).then(results => {
        setButtonClicked(false);
        return results;
      });
    } else {
      setCurrentIndex(nextIndexYes);
      moveNext();
    }
  };

  const declineSub = () => {
    // add to order
    const cartItems = combineCartItems(upsellCart, currentOffer);
    setUpsellCart(cartItems);

    const moveNext = () => {
      // disable loading
      setButtonClicked(false);

      // scroll to top
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };

    if (!hasNextOffer) {
      // submit order
      return submitOrders(
        userToken,
        cartItems,
        orderData,
        isCheckoutApiVersion2
      ).then(results => {
        setButtonClicked(false);
        return results;
      });
    } else {
      setCurrentIndex(nextIndexYes);
      moveNext();
    }
  };

  const triggerButton = () => {
    if (buttonClicked || Builder.isEditing) return;

    setButtonClicked(true);
    return setConfirmationShowPopup(true);
  };

  const ButtonContent = ({ children }) => {
    if (buttonClicked) {
      return (
        <S.LoadingButton loadingBgColor={loadingBgColor}>
          <img
            src="/images/three-dots.svg"
            width={120}
            height={30}
            alt="Loading..."
            className="button-loading"
          />
        </S.LoadingButton>
      );
    } else {
      return children;
    }
  };

  return (
    <>
      <S.Button
        className={`${buttonClicked ? "clicked" : ""} accept-button no-design`}
        onClick={triggerButton}
      >
        <ButtonContent>
          <div>{children || `{add text component}`}</div>
        </ButtonContent>
      </S.Button>
      {showConfirmationPopup && (
        <ConfirmationPopup
          version={"add-sub"}
          currentOrders={[currentOffer]}
          showPopup={showConfirmationPopup}
          setShowPopup={setConfirmationShowPopup}
          confirmSub={confirmSub}
          declineSub={declineSub}
          subProduct={subProduct}
          popupTopImage={popupTopImage}
        />
      )}
    </>
  );
};

export default AcceptButtonComponent;
