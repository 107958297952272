import _axios from "axios";
import checkoutApiUrl from "../utils/checkoutApiUrl";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const submitUpgradeToSub = async (
  token,
  orders,
  orderData = {},
  subProduct
) => {
  if (!token) {
    console.log("Submit Order Error: Token not present!");
    return;
  }

  try {
    const variantIds =
      orders.map(order => {
        return order.variantId;
      }) || [];

    const discountCode = subProduct["discountCode"];
    const variantId = subProduct["variantId"];

    return await _axios
      .post(`${checkoutApiUrl()}/checkout/${token}/upsell/upgrade-otp-to-sub`, {
        discount_codes: [discountCode],
        items: [
          {
            variant_id: variantId
          }
        ]
      })
      .then(response => {
        // drop cookie
        const userCookies = cookies.get(orderData.customer.email) || [];
        cookies.set(
          orderData.customer.email,
          [...new Set([...userCookies, ...variantIds])],
          { path: "/" }
        );
        window.location.href = `${checkoutApiUrl()}/checkout/${token}/receipt`;
      });
  } catch (e) {
    console.log("error submitting orders");
    console.log(e);
  }
};

export default submitUpgradeToSub;
